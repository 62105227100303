<template>
    <div v-if="canAccess('logs_read')" class="p-grid">
        <div class="p-col-12">
            <div class="card card-w-title">
                <Toolbar class="p-col-12 p-mb-6">
                    <template v-slot:left>
                        <div class="container">
                            <div class="row" style="display: inline-flex; margin-top: 20px">
                                <div class="p-field p-fluid element">
                                <span class="p-float-label">
                                     <Calendar id="calendar" :showIcon="true" v-model="from"  dateFormat="dd/mm/yy"  :yearNavigator="true" yearRange="2018:2050" />
                                    <label for="to">Fecha desde</label>

                                </span>
                                </div>
                                <div class="p-field p-fluid element">
                                <span class="p-float-label">
                                     <Calendar id="to" :showIcon="true" v-model="to"  dateFormat="dd/mm/yy"  :yearNavigator="true" yearRange="2018:2050" />
                                    <label for="to">Fecha  hasta</label>
                                </span>
                                </div>

                                <div class="p-grid p-fluid filter element">
                                    <div class="p-col-12 p-md-1">
                                        <Button icon="pi pi-search" class="p-button-info p-mr-2" @click="filter"></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Toolbar>

                <ProgressBar mode="indeterminate" v-if="processing"/>

                <DataTable :value="items" :filters="filters" class="p-datatable-responsive"
                           v-model:selection="currentItem" selectionMode="single" dataKey="id" :paginator="true"
                           paginatorPosition="bottom" :rows="10">
                    <template #header>
                        <div class="table-header">
                            <h5 class="p-m-0">Lista de logs</h5>
                            <div>
                                <div class="p-inputgroup">
									<span class="p-inputgroup-addon">
										<i class="pi pi-search"></i>
									</span>
                                    <InputText v-model="filters['global']" placeholder="Search..."/>
                                </div>
                            </div>
                        </div>
                    </template>

                    <Column field="created_at" header="Fecha" :sortable="true"></Column>
                    <Column field="description" header="Descripción" :sortable="true"></Column>
                    <Column field="causer_type" header="Origen" :sortable="true"></Column>
                </DataTable>
            </div>
        </div>
    </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>

  </div>

</template>

<script>
    import service from '../../service/catalog.service'
    import {mapState} from "vuex";
    import moment from 'moment'
import bouncer from "../../../../helpers/bouncer";
    export default {
      mixins:[bouncer],
        name: 'PaymentType',
        data() {
            return {
                path: 'logs',
                confirmDeleteModal: false,
                processing: false,
                submitted: false,
                formDialog: false,
                filters: {},
                items: null,
                currentItem: null,
                layout: 'list',
                from: new Date(),
                to: new Date(),
                params: {
                    from: moment().format('YYYY-MM-DD'),
                    to: moment().format('YYYY-MM-DD'),
                }
            }
        },
        carService: null,
        eventService: null,
        nodeService: null,
        created() {
          if(this.canAccess('logs_read')){
            this.getData(this.params)
          }
        },
        mounted() {
        },
        methods: {
            filter() {
                if (!this.from || !this.to) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Error',
                        detail: 'Parámetros incorrectos',
                        life: this.$utils.toastLifeTime()
                    });
                    return;
                }
                const params = {};
                params.from = moment(this.from).format("YYYY-MM-DD");
                params.to = moment(this.to).format("YYYY-MM-DD");

                this.getData(params);
            },
            getData(params) {
                this.$nextTick(() => {

                    this.processing = true;

                    service.get(this.path, params).then(x => {
                        this.items = x.data;
                    }).catch(() => {
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: 'se a producido un error',
                            life: this.$utils.toastLifeTime()
                        });
                    }).finally(() => {
                        this.processing = false;
                    })
                })
            },
        },
        computed: {
            ...mapState('auth', ['isAdmin']),
        }
    }
</script>

<style scoped>
    .table-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .element {
        margin-left: 5px;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .p-progressbar {
        height: 1px;
    }
</style>
